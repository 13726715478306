import React from 'react';
import { Link } from 'react-router-dom';
import { FaPaperPlane } from 'react-icons/fa';
import { FaFacebookF, FaYelp } from 'react-icons/fa';
import { AiOutlineGoogle } from 'react-icons/ai';
import { BsTwitter, BsInstagram } from 'react-icons/bs';

const HomeThreeFooter = () => {
   return (
      <>
         <footer className="theme-dark-bg2">
            <div className="tp-footer-area-two pt-120 pb-0">
               <div className="container">
                  <div className="row justify-content-center">
                     <div className="col-lg-4 col-sm-6">
                        <div className="tp-footer-widget footer-col-1 mb-30 wow fadeInUp" data-wow-delay=".3s">
                           <div className="tp-footer-info tp-footer-info-three">
                              <div className="tp-footer-info-logo mb-35">
                                 <Link to="/"><img src="assets/img/logo/AllStarSewer.png" className="img-fluid" alt="img not found"/></Link>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-3 col-sm-6">
                        <div className="tp-footer-widget footer-col-2 mb-30 wow fadeInUp" data-wow-delay="1.2s">
                           <h4 className="tp-footer-widget-title mb-25">Our Socials</h4>
                           <div className="tp-footer-info-social tp-footer-info-social-three">
                              <a href="https://www.facebook.com/profile.php?id=100091741492368"><i><FaFacebookF className='icon' /> </i></a>
                              <a href="https://www.instagram.com/allstarsewerllc"><i><BsInstagram className='icon' /> </i></a>
                              <a href="https://www.yelp.com/biz/all-star-sewer-lake-hiawatha-2"><i><FaYelp className='icon' /> </i></a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="tp-copyright-area-two theme-dark-bg2 z-index pt-30 pb-30">
               <div className="container">
                  <div className="row">
                     <div className="col-12">
                        <div className="tp-copyright tp-copyright-two text-center">
                           <p className="m-0">Copyright ©2023 <span>All Star Sewer LLC</span>. All Rights Reserved Copyright</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

/*
<a href="/"><i><BsTwitter className='icon' /> </i></a>
<a href="/"><i><BsInstagram className='icon' /> </i></a>
<a href="/"><i><AiOutlineGoogle className='icon' /> </i></a>
*/

export default HomeThreeFooter;
