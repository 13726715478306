import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

const ServicesDetailsBreadcrumb = () => {
   return (
      <>
         <Breadcrumb title="House" subTitle="Cleaning" smText='House Cleaning'></Breadcrumb>
      </>
   );
};

export default ServicesDetailsBreadcrumb;