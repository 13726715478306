import React from 'react';
import { Link } from 'react-router-dom';

const HomeServiceItem = ({icon_name,title,text}) => {
   return (
      <>
         <div className="col-xl-5 col-sm-6 s">
            <div className="tp-services white-bg mb-30 wow fadeInUp" data-wow-delay=".2s">
               <div className="tp-services-text fix">
                  <h4 className="tp-services-text-title hover-theme-color">
                        {title}</h4>
               </div>
            </div>
         </div>
      </>
   );
};

export default HomeServiceItem;