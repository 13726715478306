import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import Sidebar from '../../../../components/Sidebar/Sidebar';

const HomeThreeNavBar = () => {
   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const [sticky, setSticky] = useState(false);
   const stickyNavbar = () => {
      if (window.scrollY > 80) {
         setSticky(true)
      }
      else {
         setSticky(false)
      }
   }
   window.addEventListener('scroll', stickyNavbar);
   return (
      <>
         <header>
            <div className={sticky ? "sticky-menu tp-header-area-three header-sticky" : "tp-header-area-three header-sticky"}>
               <div className="tp-custom-container">
                  <div className="row justify-content-xl-center align-items-center">
                     <div className="col-xxl-2 col-xl-3 col-8">
                        <div className="tp-header-logo-three" style={{ maxWidth: '100%', height: 'auto' }}>
                           <Link to="/" style={{ display: 'flex' }}>
                              {/* Use display:flex */}
                              <img
                                 src="assets/img/logo/AllStarSewer.png"
                                 className="img-fluid"
                                 alt="logo not found"
                                 style={{ maxWidth: '100%', height: 'auto', marginRight: '10px' }}
                              />
                              <img
                                 src="assets/img/logo/Accredit.jpg"
                                 className="img-fluid"
                                 alt="logo not found"
                                 style={{ maxWidth: '50%', height: '50%', marginRight: '10px', marginTop: '10px'}}
                              />
                           </Link>
                        </div>
                     </div>
                     <div className="col-xxl-2 col-xl-6 col-4">
                        {/* <!-- mobile menu activation --> */}
                        <div className="side-menu-icon d-xl-none text-end">
                           <button onClick={handleShow} className="side-toggle border-0 bg-transparent">
                              <i> <FaBars className='bar_icon' /> </i></button>
                        </div>
                     </div>
                     <div className="col-xxl-7 col-xl-3 d-none d-xl-block">
                        <div className="tp-header-right-three">
                           <div className="tp-header-number-three">
                              <span>Call Us : <a href="tel:9735670337">+1 (973) 567-0337</a></span>
                           </div>                           <div className="tp-header-number-three">
                              <span>Email Us : <a href="mailto:allstarsewerllc@gmail.com">allstarsewerllc@gmail.com</a></span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>

         <Sidebar show={show} handleClose={handleClose}></Sidebar>
      </>
   );
};

export default HomeThreeNavBar;
