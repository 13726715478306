import React from 'react';
import ServicesTestimonials from '../../../Services/Services/ServicesTestimonial/ServicesTestimonials';

const HomeTwoTestimonials = () => {
    return (
        <>
            <ServicesTestimonials/>
        </>
    );
};

export default HomeTwoTestimonials;