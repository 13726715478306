import React from 'react';
import PricingAffordablePackage from '../../../Pricing/PricingAffordablePackage/PricingAffordablePackage';

const ServicesPricing = () => {
    return (
        <>
            <PricingAffordablePackage/>
        </>
    );
};

export default ServicesPricing;