import React from 'react';
import About from './About/About';
import Blogs from './Blogs/Blogs';
import Brand from './Brand/Brand';
import ChoseArea from './ChoseArea/ChoseArea';
import Contact from './Contact/Contact';
import Cta from './Cta/Cta';
import Features from './Features/Features';
import Footer from './Footer/Footer';
import HeroSection from './HeroSection/HeroSection';
import NavBar from './NavBar/NavBar';
import ServiceArea from './ServiceArea/ServiceArea';
import PopularServices from './PopularServices/PopularServices';
import Projects from './Projects/Projects';
import Testimonial from './Testimonial/Testimonial';

const Home = () => {
    return (
        <>
            <NavBar/>
            <HeroSection/>
            <ChoseArea/>
            <ServiceArea/>
            <Testimonial/>
            <Contact/>
            <Footer/>
        </>
    );
};
/*
    <Features/>
    <About/>
    <PopularServices/>
    <Projects/>
    <Testimonial/>
    <Cta/>
    <ChoseArea/>
    <Blogs/>
    <Contact/>
    <Footer/>
*/
export default Home;