import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

const TeamDetailsBreadcrumb = () => {
    return (
        <>
            <Breadcrumb title="Georgia" subTitle="Mishel" smText='Georgia Mishel'></Breadcrumb>
        </>
    );
};

export default TeamDetailsBreadcrumb;