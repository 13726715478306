import React from 'react';
import HomeThreeSingleChoseArea from '../../../../components/HomeThreeSingleChoseArea/HomeThreeSingleChoseArea';

const HomeThreeChoseArea = () => {
   return (
      <>
         <section className="tp-choose-area-three position-relative mt-120 pb-100">
            <div className="tp-choose-area-three-img">
               <img src="assets/img/about/about-img-5.jpg" alt="img-not-found"/>
                  <div className="tp-choose-three-year tp-choose-three-year-responsive mb-50">
                     <div className="tp-choose-three-year-inner">
                        <h3>5+</h3>
                           <h4>Years <br/>Of Experience</h4>
                     </div>
                  </div>
            </div>
            <div className="container">
               <div className="row align-items-end justify-content-center">
                  <div className="col-xl-6 text-end d-xl-block d-none">
                     <div className="tp-choose-three-year mb-50">
                        <div className="tp-choose-three-year-inner">
                           <h3>5+</h3>
                           <h4>Years <br/>Of Experience</h4>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-10">
                     <div className="tp-choose-three">
                        <div className="section-title-wrapper mb-25 pt-100">
                           <h2 className="tp-section-title-two color-theme-blue">About Us<br/></h2>
                           <h5 className="tp-section-subtitle-three mb-20"> Small Business Owner</h5>
                        </div>
                        <p className="mb-10">Since our opening, we have honed our craft and become masters of the services we offer. Our commitment to providing exceptional services and unparalleled customer care has earned us a loyal community of customers who keep coming back to us.</p>
                        <p className="mb-10">We are continuously improving and expanding our offerings to ensure that we are always serving the Northern NJ area in the best way possible. As natives of New Jersey and a local company, we are dedicated to providing unbeatable or matching advertised prices to our customers.</p>
                        <p className="mb-45">At All Star Sewer LLC, we take pride in being a small business that is run by someone who genuinely cares about fixing the drainage issues in our local communities. We believe that our work not only improves the quality of life for our customers but also contributes to the well-being of our community as a whole.</p>
                        <div className="row mb-10">

                           <HomeThreeSingleChoseArea icon="delivery-box" title="24-hour service" />
                           <HomeThreeSingleChoseArea icon="snowfall" title="Quick Response" />

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeThreeChoseArea;