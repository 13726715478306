import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';
import "swiper/css/pagination";
import AppointmentSingleFeedback from '../../../../components/AppointmentSingleFeadback/AppointmentSingleFeadback';

SwiperCore.use([Navigation, Pagination]);

const HomeThreeTestimonial = () => {
   return (
      <>
         <section className="tp-testimonial-area-three pt-0 pb-100 bg-gray-light">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="section-title-wrapper text-center mb-55">
                        <h2 className="tp-section-title-two color-theme-blue">Testimonial's </h2>
                        <h4 className="tp-section-subtitle-three mb-20"> Keeping Customers Satisfied</h4>
                     </div>
                  </div>
               </div>
               <div className="tp-testimonial-three-shadow common-dots shadow-0">
                  <div className="tp-testimonial-three-active swiper-container">
                  <Swiper
                        spaceBetween={30}
                        slidesPerView={1}
                        style={{paddingBottom: '80px' }}
                        className='swiper-wrapper'
                        pagination={{ clickable: true }}
                        autoplay={{ delay: 6000 }}
                        breakpoints={{
                           550: {
                              slidesPerView: 1,
                           },
                           768: {
                              slidesPerView: 1,
                           },
                           992: {
                              slidesPerView: 2,
                           },
                           1200: {
                              slidesPerView: 3,
                           },
                        }}
                        navigation={{ nextEl: '.service-button-next', prevEl: '.service-button-prev', }}
                        // onSlideChange={() => console.log('slide change')}
                        // onSwiper={(swiper) => console.log(swiper)}
                     >
                        <SwiperSlide>
                           <AppointmentSingleFeedback image_num="1" title="Client" name="Ray Vasquez" testimonial="Highly recommend! The problem was diagnosed & they immediately went to work! They Did a great job assessing the issue and cleaning up thank you again will be using you soon."/>
                        </SwiperSlide>

                        <SwiperSlide>
                           <AppointmentSingleFeedback image_num="2" title="Client" name="Steven Sardini" testimonial="All Star Sewer did a fantastic job cleaning out two clogged sinks in my house. The team was in and out and left no mess. Thanks!"/>
                        </SwiperSlide>

                        <SwiperSlide>
                           <AppointmentSingleFeedback image_num="3" title="Client" name="Gary Maguire" testimonial="All Star Sewer provided excellent drain cleaning service. Their technician was knowledgeable and efficient, clearing the clog without any hassle. Highly recommended."/>
                        </SwiperSlide>

                        <SwiperSlide>
                           <AppointmentSingleFeedback image_num="4" title="Client" name="Thomas Sargent" testimonial="I had a clogged drain, and All Star Sewer came to the rescue. The technician they sent was friendly, knowledgeable, and quickly resolved the issue. Their state-of-the-art equipment ensured a thorough cleaning without any damage. Prompt, professional, and reasonably priced."/>
                        </SwiperSlide>

                        <SwiperSlide>
                           <AppointmentSingleFeedback image_num="5" title="Client" name="Donna Brown" testimonial="I recently hired All Star Sewer for a plumbing issue, The individual they sent was skilled, efficient and swift. I was thoroughly impressed with All Star Sewer's expertise and commitment to customer satisfaction."/>
                        </SwiperSlide>

                        <SwiperSlide>
                           <AppointmentSingleFeedback image_num="6" title="Client" name="Eric Reed" testimonial="Very dedicated, would hire again!"/>
                        </SwiperSlide>

                        <div className="swiper-service-pagination slide-dots"></div>
                     </Swiper>
                  </div>
                  {/* <!-- If we need pagination --> */}
                  <div className="swiper-pagination-testimonial slide-dots "></div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeThreeTestimonial;