import React from 'react';
import HomeServiceItem from '../../../../components/HomeServiceItem/HomeServiceItem';

const ServiceArea = () => {
   return (
      <>
         <section className="tp-service-area-three pt-100 pb-100 bg-gray-light">
                  <div className="container">
                     <div className="row justify-content-center">
                        <div className="col-lg-8">
                           <div className="tp-section-title-wrapper text-center mb-55 wow fadeInUp" data-wow-delay=".1s">
                              <h2 className="tp-section-title">Services Available </h2>
                           </div>
                        </div>
                     </div>
                     <div className="row justify-content-center">

                        <HomeServiceItem icon_name="pot" title="Main line Snaking"/>

                        <HomeServiceItem icon_name="vacuum-cleaner" title="Kitchen sink clog" />

                        <HomeServiceItem icon_name="house-cleaning" title="Tub clog" />

                        <HomeServiceItem icon_name="desk" title="Toilet clog" />

                        <HomeServiceItem icon_name="cleaning" title="Floor drains" />

                        <HomeServiceItem icon_name="window" title="Laundry lines" />

                        <HomeServiceItem icon_name="cleaning" title="Septic lines" />

                        <HomeServiceItem icon_name="window" title="Storm drains" />

                     </div>
                  </div>

         </section>
      </>
   );
};

export default ServiceArea;